<template>
  <div class="voice-animation">
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="32" cy="32" r="32" fill="#4480F3"/>
      <rect x="23" y="31" width="2" height="5" rx="1" fill="white">
        <animate attributeName="height" values="5;15;8;14;5;" :dur="duration" repeatCount="indefinite" />
        <animate attributeName="y" values="31;22;31;" :dur="duration" repeatCount="indefinite" />
      </rect>
      <rect x="27" y="26" width="2" height="8" rx="1" fill="white">
        <animate attributeName="height" values="8;17;8;" :dur="duration" repeatCount="indefinite" />
        <animate attributeName="y" values="26;29;22;26;" :dur="duration" repeatCount="indefinite" />
      </rect>
      <rect x="35" y="28" width="2" height="11" rx="1" fill="white">
        <animate attributeName="height" values="11;13;10;11;" :dur="duration" repeatCount="indefinite" />
        <animate attributeName="y" values="28;22;32;22;28;" :dur="duration" repeatCount="indefinite" />
      </rect>
      <rect x="39" y="28" width="2" height="4" rx="1" fill="white">
        <animate attributeName="height" values="4;16;4;11;4;" :dur="duration" repeatCount="indefinite" />
        <animate attributeName="y" values="28;24;35;22;28;" :dur="duration" repeatCount="indefinite" />
      </rect>
      <rect x="31" y="22" width="2" height="20" rx="1" fill="white">
        <animate attributeName="height" values="20;4;16;7;20;" :dur="duration" repeatCount="indefinite" />
        <animate attributeName="y" values="22;30;24;25;26;25;22;" :dur="duration" repeatCount="indefinite" />
      </rect>
    </svg>
  </div>
</template>

<script>
export default {
  name: "VoiceAnimation",

  data() {
    return {
      duration: '2.0s'
    }
  }
}
</script>
