<template>
  <ListPageContainer :title="$t('views.search.title')">
    <div class="search">
      <r-row class="px-4">
        <r-col :cols="{ widest: 4, wide: 4, middle: 2, narrow: 6 }">
          <div class="search__container">
            <VoiceButton @click.native="initRecognition"
                         :state="btnState"
                         class="search__button"
                         :is-voice-active="isInProcess" />

            <r-input
                class="search__input"
                v-model="input"
                before-icon="search"
                :label="$t('misc.find')"
                rounded
                size="lanassa"
                is-clear
            />
          </div>
        </r-col>
      </r-row>

      <Keypad v-if="!results"
              :class="{ 'disabled': progress }"
              @keyevent="addSymbol"
              @delete="deleteSymbol"
              @addspace="addSpace"
              @gosearch="search"
      />

      <Loader height="20vh" size="diomis" v-if="progress && !results" />

      <div v-if="!progress && results && input !== null">
        <SearchResults :results="results" />
      </div>

      <div v-if="!progress && (Array.isArray(results) && results.length === 0) && input !== null">
        <NothingFound text="Попробуйте другой запрос" />
      </div>
    </div>
  </ListPageContainer>
</template>

<script>
import ListPageContainer from "@/components/Containers/ListPageContainer";
import Keypad from "@/components/Miscellaneous/Keypad";
import Loader from "@/components/Loader/Loader";
import SearchResults from "@/components/SearchParts/SearchResults";
import VoiceButton from "@/components/SearchParts/VoiceButton";
import {mapGetters} from "vuex";
import NothingFound from "@/components/Miscellaneous/NothingFound";

export default {
  name: "SearchScreen",

  components: {
    NothingFound,
    VoiceButton,
    SearchResults,
    Loader,
    Keypad,
    ListPageContainer,
  },

  computed: {
    ...mapGetters(['GET_SINGLE_CITY']),

    singleCityId() {
      return {
        cityId: this.GET_SINGLE_CITY ? this.GET_SINGLE_CITY[0].id : ''
      }
    }
  },

  watch: {
    input(val) {
      if (val === null) this.results = null;
    }
  },

  data() {
    return {
      input: null,
      results: null,
      progress: false,

      resultVoice: '',
      lang: 'ru-RU',
      recognition: null,
      recognizing: false,
      btnState: 'microphone',
      isInProcess: false,
    }
  },

  methods: {
    initRecognition() {
      if (this.input !== null) {
        this.input = null;
        this.results = null;
      }
      this.isInProcess = true;
      this.btnState = 'voice';
      
      const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

      if (!SpeechRecognition) {
        throw new Error('Speech Recognition does not exist on this browser. Use Chrome or Firefox.');
      }

      let _this = this;
      this.recognition = new SpeechRecognition();
      this.recognition.lang = this.lang;
      
      navigator.mediaDevices.getUserMedia({ audio: true });

      this.recognition.onresult = function (event) {
        for (let i = event.resultIndex; i < event.results.length; ++i) {
          _this.resultVoice = event.results[i][0].transcript;
        }
      }

      this.recognition.onend = function() {
        if (_this.resultVoice !== '') {
          _this.onResult(_this.resultVoice);
        }
      }

      this.recognition.start();
    },
    onResult(result) {
      this.isInProcess = false;
      this.btnState = 'microphone';
      this.input = result;
      this.search();
      this.recognition.stop();
    },
    cancelVoice() {
      if (this.btnState === 'voice') this.recognition.abort();
      this.isInProcess = false;
      this.btnState = 'microphone';
    },
    addSymbol(symbol) {
      if (this.input === null) {
        this.input = '';
      }
      this.input += symbol;
      this.cancelVoice();
    },
    deleteSymbol() {
      this.input = this.input.substring(0, this.input.length - 1);
      this.cancelVoice();
    },
    addSpace() {
      if (this.input === null) {
        this.input = '';
      }
      this.input += ' ';
      this.cancelVoice();
    },
    search() {
      const cityId = this.singleCityId?.cityId;
      this.progress = true;

      this.$api.getSearchResults(this.input, cityId).then(resolve => {
        this.results = resolve.data;
        this.progress = false;
      });
    }
  }
}
</script>

<style lang="scss">
.search {
  height: 100%;

  &__container {
    display: flex;
    align-items: center;
  }

  &__button {
    flex-shrink: 0;
    margin-right: 8px;
  }

  &__input {
    flex: 1;
  }

  .RButtonSimple {
    width: 64px !important;
    height: 64px !important;
    padding: 22px !important;
    .RIcon {
      font-size: 20px !important;
      line-height: 20px !important;
    }
  }
}
</style>
