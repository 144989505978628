<template>
  <div class="button-container">
    <div class="voice-btn" :class="{ 'transparent': state === 'voice' }">
      <r-icon icon="microphone" fill="rocky" v-if="state === 'microphone'"/>

      <VoiceAnimation v-if="state === 'voice'"/>
    </div>

    <div v-if="isVoiceActive" class="button-container__popover mozzarella harakiri--text amelie--bg">
      {{ $t('misc.speak') }}
    </div>
  </div>
</template>

<script>
import VoiceAnimation from "@/components/SearchParts/VoiceAnimation";

export default {
  name: "VoiceButton",
  components: { VoiceAnimation },
  props: {
    state: {
      type: String,
      default: () => 'microphone'
    },
    isVoiceActive: {
      type: [String, Boolean],
      default: () => false
    }
  }
}
</script>

<style lang="scss">
.button-container {
  position: relative;

  &__popover {
    position: absolute;
    bottom: -28px;
    left: -4px;
    filter: drop-shadow(0px 4px 16px rgba(12, 16, 24, 0.12));
    padding: 4px 8px;
    border-radius: 8px;
  }
}

.voice-btn {
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: rgba(68, 128, 243, 0.12);

  &.transparent {
    background: transparent;
  }
}
</style>
