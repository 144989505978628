<template>
  <div class="keypad trainspotting--bg">
    <div class="keypad__row">
      <div class="keypad__key maasdam harakiri--text avengers--bg"
           v-for="(key, index) in numberKeys"
           :key="index"
           @click="$emit('keyevent', key)"
      >
        {{ key }}
      </div>
      <div class="keypad__key avengers--bg" @click="$emit('delete')">
        <r-icon icon="delete-symbol" fill="rocky" size="20" />
      </div>
    </div>

    <div class="keypad__pad">
      <div class="keypad__row--keys" v-for="(row, index) in currentSymbolsSet" :key="index">
        <div class="keypad__key keypad__key--shadow maasdam harakiri--text amelie--bg"
             v-for="(key, index) in row"
             :key="index"
             @click="$emit('keyevent', key)"
        >
          {{ key }}
        </div>
      </div>
    </div>

    <div class="keypad__row keypad__row--additional">
      <div class="keypad__key lang sulguni rocky--text avengers--bg"
           @click="changeKeysSet">
        {{ inputLang === 'rus' ? 'Eng' : 'Рус' }}
      </div>

      <div class="keypad__key space keypad__key--shadow sulguni harakiri--text amelie--bg"
           @click="$emit('addspace')">
        {{ $t('misc.space') }}
      </div>

      <r-button
          class="search"
          :title="$t('misc.find')"
          @click="$emit('gosearch')"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "Keypad",

  computed: {
    currentSymbolsSet() {
      if (this.inputLang === 'eng') return this.symbolKeysEng;
      return this.symbolKeysRus;
    }
  },

  data() {
    return {
      numberKeys: [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 0
      ],
      symbolKeysRus: [
        ['й', 'ц', 'у', 'к', 'е', 'н', 'г', 'ш', 'щ', 'з', 'х'],
        ['ф', 'ы', 'в', 'а', 'п', 'р', 'о', 'л', 'д', 'ж', 'э'],
        ['я', 'ч', 'с', 'м', 'и', 'т', 'ь', 'б', 'ю', 'ъ', 'ё']
      ],
      symbolKeysEng: [
        ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
        ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l'],
        ['z', 'x', 'c', 'v', 'b', 'n', 'm']
      ],
      inputLang: 'rus'
    }
  },

  methods: {
    changeKeysSet() {
      if (this.inputLang === 'rus') {
        this.inputLang = 'eng'
      } else {
        this.inputLang = 'rus'
      }
    }
  }
}
</script>

<style lang="scss">
.keypad {
  position: absolute;
  bottom: 112px;
  left: 0;
  right: 0;
  box-shadow: 0px 8px 32px rgba(12, 16, 24, 0.12);
  padding: 8px;

  &.disabled {
    opacity: 0.32;
    &:after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    gap: 0 4px;
    margin-bottom: 12px;

    &--keys {
      display: flex;
      flex-wrap: wrap;
      gap: 12px 4px;
    }

    &--additional {
      .lang {
        flex: 1;
      }
      .space {
        flex: 4;
      }
      .search {
        flex: 1;
        align-items: center;
      }
    }
  }

  &__pad {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px 0;
    margin-bottom: 12px;

    .keypad__key {
      min-width: 44px;
      flex: 1;
    }
  }

  &__key {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    background: var(--rir-amelie);
    border-radius: 8px;

    &--shadow {
      filter: drop-shadow(0px 2px 8px rgba(12, 16, 24, 0.08));
    }
  }
}
</style>
