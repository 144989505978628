<template>
  <div class="search-results">
    <r-row class="px-4">
      <r-col :cols="{ widest: 4, wide: 4, middle: 2, narrow: 6 }">
        <div class="search-results__item" v-for="item in filteredByToday" :key="item.id" @click="goTo(item)">
          <div class="search-results__item-title bryndza harakiri--text" v-html="item.title"></div>
          <div class="search-results__item-desc mozzarella troy--text" v-if="item.type_title">
            {{ item.type_title }}
          </div>
        </div>
      </r-col>
    </r-row>
  </div>
</template>

<script>
export default {
  name: "SearchResults",

  props: {
    results: {
      type: [Array, Object],
      default: () => []
    }
  },

  computed: {
    filteredByToday() {
      const today = new Date().getTime();
      const filteredEventsFromToday = this.results.filter(item => item.entity === 'events').filter(item => item.started_at * 1000 >= today);
      const withoutEvents = this.results.filter(item => item.entity !== 'events');
      return [...filteredEventsFromToday, ...withoutEvents];
    }
  },

  methods: {
    goTo(object) {
      if (object.entity === 'places') this.$router.push({ path: `/places/${object.id}` });
      if (object.entity === 'trips') this.$router.push({ path: `/routes/${object.id}` });
      if (object.entity === 'excursions') this.$router.push({ path: `/excursions/${object.id}` });
      if (object.entity === 'events') this.$router.push({ path: `/events/${object.id}` });
      if (object.entity === 'news') this.$router.push({ path: `/news/${object.id}` });
    }
  }
}
</script>

<style lang="scss">
.search-results {
  &__item {
    padding: 16px 0;
    &:not(:last-child) {
      border-bottom: 1px solid var(--rir-apocalypse);
    }
  }
  &__item-title {
    margin-bottom: 4px;

    span {
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
    }
  }
}
</style>
