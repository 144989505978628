<template>
  <div class="list">
    <r-row v-if="title" class="list__title px-4">
      <r-col :cols="{ widest: 12, wide: 12, middle: 6, narrow: 6 }">
        <h2 class="ricotta harakiri--text">
          {{ title }}
        </h2>
      </r-col>
    </r-row>

    <slot />
  </div>
</template>

<script>
export default {
  name: "ListPageContainer",

  props: {
    title: {
      type: String,
      default: () => '',
    }
  },
}
</script>

<style lang="scss">
.list {
  height: auto;
  padding-top: 8px;
  padding-bottom: 144px;

  &__title {
    margin-bottom: 16px;
  }
}
</style>
